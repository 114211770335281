const englishAboutUsLinks = [
    {
      id: "mm-ab-about",
      show: true,
      class: "",
      textMobile: "About Us",
      text: "About WaFd Bank",
      url: "/about-us"
    },
    {
      id: "mm-ab-wafd-sports",
      show: true,
      class: "ml-3",
      textMobile: "WaFd Sports",
      text: "WaFd Sports",
      url: "/about-us/wafd-sports"
    },
    {
      id: "mm-ab-careers",
      show: true,
      class: "ml-3",
      text: "Careers",
      url: "/about-us/banking-careers"
    },
    {
      id: "mm-ab-community-relations",
      show: true,
      class: "ml-3",
      text: "Community Relations",
      url: "/about-us/community-giving"
    },
    {
      id: "mm-ab-investor-relations",
      show: true,
      class: "ml-3",
      text: "Investor Relations",
      url: "/about-us/investor-relations"
    },
    {
      id: "mm-ab-faqs",
      show: true,
      class: "ml-3",
      text: "FAQs",
      url: "/customer-service/faq"
    },
    {
      id: "mm-ab-contact-us",
      show: true,
      class: "ml-3",
      text: "Contact Us",
      url: "/about-us/contact-us"
    }
  ],
  englishMegaMenusData = [
    // PERSONAL BANKING
    {
      id: "mm-pb-dropdown",
      labelId: "mm-pb-dropdown-label",
      title: "Personal",
      landingTitle: "WaFd Bank Personal Banking",
      landingUrl: "/personal-banking",
      showScrollIcon: true,
      columns: [
        {
          id: "mmc-pb-checking",
          show: true,
          type: "links",
          class: "",
          title: "Checking",
          url: "/personal-banking/checking-account",
          menus: [
            {
              id: "mml-pb-compare-checking",
              show: false,
              text: "Compare Accounts",
              url: "/personal-banking/checking-account"
            },
            {
              id: "mml-pb-free-checking",
              show: true,
              text: "Free Checking",
              url: "/personal-banking/free-checking-account"
            },
            {
              id: "mml-pb-free-spending",
              show: true,
              text: "Spending Account",
              url: "/personal-banking/spending-account"
            },
            {
              id: "mml-pb-green-checking",
              show: true,
              text: "Rewards and Premium Rewards Checking",
              url: "/personal-banking/checking-account/rewards-checking"
            },
            {
              id: "mml-pb-stellar-checking",
              show: true,
              text: "Interest Checking",
              url: "/personal-banking/checking-account/interest-rewards-checking"
            },
            {
              id: "mml-pb-debit-cards",
              show: true,
              text: "Debit Cards",
              url: "/personal-banking/debit-cards"
            }
          ]
        },
        {
          id: "mmc-pb-savings",
          show: true,
          type: "links",
          class: "",
          title: "Savings",
          url: "/personal-banking/savings-account",
          menus: [
            {
              id: "mml-pb-savings-money-market",
              show: true,
              text: "Savings and Money Markets",
              url: "/personal-banking/savings-account"
            },
            {
              id: "mml-pb-youth-savings",
              show: true,
              text: "Kid and Teen Savings",
              url: "/personal-banking/savings-account/youth-savings-account"
            },
            {
              id: "mml-pb-cds",
              show: true,
              text: "Certificates of Deposit (CDs)",
              url: "/personal-banking/savings-account/certificate-of-deposit"
            },
            {
              id: "mml-pb-savings-account",
              show: false,
              text: "Savings",
              url: "/personal-banking/savings-account"
            },
            {
              id: "mml-pb-money-market",
              show: false,
              text: "Money Market",
              url: "/personal-banking/money-market"
            },
            {
              id: "mml-pb-retirement-planning",
              show: true,
              text: "Retirement Savings",
              url: "/personal-banking/retirement-ira-accounts"
            },
            {
              id: "mml-pb-education-savings",
              show: true,
              anchorLink: true,
              text: "Education Savings",
              url: "/personal-banking/savings-account/youth-savings-account#coverdell-section"
            }
          ]
        },
        {
          id: "mmc-pb-mortgages",
          show: true,
          type: "links",
          class: "",
          title: "Mortgage",
          url: "/personal-banking/home-loans",
          menus: [
            {
              id: "mml-pb-mortgatge-overview",
              show: false,
              text: "Overview",
              url: "/personal-banking/home-loans"
            },
            {
              id: "mml-pb-mortgage-rates",
              show: true,
              text: "Rates",
              url: "/personal-banking/home-loans/mortgage-rates"
            },
            {
              id: "mml-pb-contact-loan-officer",
              show: true,
              text: "Contact a Loan Officer",
              url: "/personal-banking/contact-loan-officer"
            },
            {
              id: "mml-pb-home-equity-loans",
              show: true,
              text: "Home Equity",
              url: "/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan"
            },
            {
              id: "mml-pb-home-buyer",
              show: true,
              text: "First Time Buyer",
              url: "/personal-banking/home-loans/first-time-home-buyer-loans"
            },
            {
              id: "mml-pb-smart-start",
              show: true,
              text: "Smart Start",
              url: "/personal-banking/home-loans/smart-start-down-payment-assistance"
            },
            {
              id: "mml-pb-home-loans",
              show: true,
              text: "Home Loans",
              url: "/personal-banking/home-loans/home-mortgage"
            },
            {
              id: "mml-pb-home-loans-apply",
              show: false,
              text: "Apply for a Loan",
              url: "/personal-banking/home-loans/apply-online"
            },
            {
              id: "mml-pb-mortgatge-types",
              show: false,
              text: "Mortgage Types",
              url: "/personal-banking/home-loans/mortgage-types"
            },
            {
              id: "mml-pb-refinance",
              show: true,
              text: "Refinancing",
              url: "/personal-banking/home-loans/mortgage-refinance"
            },
            {
              id: "mml-pb-home-improvement-loans",
              show: true,
              text: "Remodeling",
              url: "/personal-banking/home-loans/home-improvement-loans"
            },
            {
              id: "mml-pb-construction-loans",
              show: true,
              text: "Construction",
              url: "/personal-banking/home-loans/construction-loans"
            },
            {
              id: "mml-pb-lot-loans",
              show: true,
              text: "Lot Loans",
              url: "/personal-banking/home-loans/land-loans"
            },
            {
              id: "mml-cross-collateral",
              show: true,
              text: "Cross Collateral",
              url: "/personal-banking/home-loans/easy-move-cross-collateral"
            }
          ]
        },
        {
          id: "mmc-pb-credit-cards",
          show: true,
          type: "links",
          class: "",
          title: "Credit Cards",
          url: "/personal-banking/credit-cards",
          menus: [
            {
              id: "mml-pb-our-credit-cards",
              show: true,
              text: "Our Cards",
              url: "/personal-banking/credit-cards"
            },
            {
              id: "mml-pb-apply-credit-cards",
              show: false,
              text: "Apply for a new Card",
              url: "/personal-banking/apply-for-credit-cards"
            }
          ]
        },
        {
          id: "mmc-pb-personal-services",
          show: true,
          type: "links",
          title: "Banking With Us",
          //url: "/personal-banking/online-banking",
          menus: [
            {
              id: "mml-pb-online-banking",
              show: true,
              text: "Online Banking",
              url: "/personal-banking/online-banking",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-pb-mobile-app",
              show: true,
              text: "Mobile App",
              anchorLink: true,
              url: "/personal-banking/online-banking#mobile-app-section",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-pb-zelle",
              show: true,
              text: "Zelle",
              url: "/personal-banking/mobile-banking-app/zelle",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-pb-voice-banking",
              show: true,
              text: "Voice-Activated Banking",
              url: "/personal-banking/voice-recognition-technology",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-pb-select-banking",
              show: true,
              text: "Select Banking",
              url: "/personal-banking/private-banking-services",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },

            {
              id: "mml-pb-account-rates",
              show: true,
              text: "Account Rates",
              url: "/personal-banking/interest-rates",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-pb-account-fees",
              show: true,
              text: "Account Fees",
              url: "/personal-banking/account-fees",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-pb-mortgage-rates",
              show: true,
              text: "Mortgage Rates",
              url: "/personal-banking/home-loans/mortgage-rates",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-pb-homeowner-assistance",
              show: true,
              text: "Homeowner Assistance",
              url: "/personal-banking/home-loans/homeowner-assistance"
            },
            {
              id: "mml-pb-calculators",
              show: true,
              text: "WaFd Bank Calculators",
              url: "/personal-banking/online-loan-banking-calculators"
            }
          ]
        }
      ]
    },
    // BUSINESS BANKING
    {
      id: "mm-bb-dropdown",
      labelId: "mm-bb-dropdown-label",
      title: "Business",
      landingTitle: "WaFd Bank Small Business Banking",
      landingUrl: "/business-banking/small-business",
      showScrollIcon: true,
      columns: [
        {
          id: "mmc-bb-checking",
          show: true,
          type: "links",
          class: "",
          title: "Business Checking",
          url: "/business-banking/business-checking-account",
          menus: [
            {
              id: "mml-bb-simple-checking",
              show: true,
              text: "Free Business Checking",
              url: "/business-banking/business-checking-account/free-business-checking"
            },
            {
              id: "mml-bb-stellar-checking",
              show: false,
              text: "Stellar Interest Checking",
              url: "/business-banking/business-checking-account"
            },
            {
              id: "mml-bb-interest-checking",
              show: true,
              text: "Business Interest Checking",
              url: "/business-banking/business-checking-account/interest-checking"
            },
            {
              id: "mml-bb-analyzed-checking",
              show: true,
              text: "Business Analyzed Checking",
              url: "/business-banking/business-checking-account#business-checking-overview-section"
            }
          ]
        },
        {
          id: "mmc-bb-savings",
          show: true,
          type: "links",
          class: "",
          title: "Business Savings",
          url: "/business-banking/business-savings-account",
          menus: [
            {
              id: "mml-bb-savings-account",
              show: true,
              text: "Business Savings Account",
              url: "/business-banking/business-savings-account/small-business"
            },
            {
              id: "mml-bb-money-market",
              show: true,
              text: "Business Money Market",
              url: "/business-banking/business-savings-account/money-market-account"
            },
            {
              id: "mml-bb-premium-money-market",
              show: true,
              text: "Business Premium Money Market",
              url: "/business-banking/business-savings-account/high-yield-money-market-account"
            },
            {
              id: "mml-bb-cds-special",
              show: true,
              text: "Business CD Special",
              url: "/business-banking/business-savings-account#business-savings-overview-section-cds"
            },
            {
              id: "mml-bb-cds",
              show: true,
              text: "Business CDs",
              url: "/business-banking/business-savings-account#business-savings-overview-section-cds"
            }
          ]
        },
        {
          id: "mmc-bb-credit",
          show: true,
          type: "links",
          class: "",
          title: "Credit",
          url: "/business-banking/business-line-of-credit",
          menus: [
            {
              id: "mml-bb-credit-cards",
              show: true,
              text: "Small Business Credit Cards",
              url: "/business-banking/small-business-credit-card"
            },
            {
              id: "mml-bb-credit-solutions",
              show: true,
              anchorLink: true,
              text: "Credit Solutions",
              url: "/business-banking/business-line-of-credit"
            },
            {
              id: "mml-bb-line-of-credit",
              show: true,
              anchorLink: true,
              text: "Business Lines of Credit",
              url: "/business-banking/business-line-of-credit#business-lines-of-credit-section"
            },
            {
              id: "mml-bb-commercial-real-estate",
              show: true,
              anchorLink: true,
              text: "Commercial Real Estate Financing",
              url: "/business-banking/business-line-of-credit#commercial-real-estate-section"
            },
            {
              id: "mml-bb-business-term-loans",
              show: true,
              anchorLink: true,
              text: "Business Term Loans",
              url: "/business-banking/business-line-of-credit#business-term-loans-section"
            },
            {
              id: "mml-bb-ppp-forgiveness",
              show: true,
              text: "PPP Forgiveness",
              url: "/business-banking/small-business/ppp-forgiveness"
            }
          ]
        },
        {
          id: "mmc-bb-business-services",
          show: true,
          type: "links",
          class: "",
          title: "Business Services",
          url: "/business-banking/business-services",
          menus: [
            {
              id: "mml-bb-business-services-2",
              show: false,
              text: "Business Services",
              url: "/business-banking/business-services"
            },
            {
              id: "mml-bb-payment-processing-services",
              show: true,
              text: "Payment Processing Services",
              url: "/business-banking/business-services/payment-processing-services"
            },
            {
              id: "mml-bb-hr-and-payroll-services",
              show: true,
              text: "HR and Payroll",
              url: "/business-banking/business-services/hr-and-payroll-services"
            },
            {
              id: "mml-bb-point-of-sale-inventory",
              show: true,
              text: "POS Inventory",
              url: "/business-banking/business-services/pos-and-inventory-systems"
            },
            {
              id: "mml-bb-customer-engagement",
              show: true,
              text: "Customer Engagement",
              url: "/business-banking/business-services/customer-engagement"
            },
            {
              id: "mml-bb-collect-early",
              show: true,
              text: "CollectEarly&trade;",
              url: "/business-banking/business-services/collect-early"
            },
            {
              id: "mml-bb-accounting-automation",
              show: true,
              text: "Accounting Automation",
              url: "/business-banking/business-services/accounting-automation"
            },
            {
              id: "mml-bb-cash-flow-management",
              show: true,
              text: "Cash Flow Management",
              url: "/business-banking/business-services/cash-flow-management"
            }
          ]
        },
        {
          id: "mmc-bb-personal-services",
          show: true,
          type: "links",
          title: "Banking With Us",
          //url: "/business-banking/online-banking",
          menus: [
            {
              id: "mml-bb-online-banking",
              show: true,
              text: "Online Banking",
              url: "/business-banking/online-banking",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-bb-treasury-express",
              externalLink: true,
              show: true,
              text: "Treasury Express Login",
              url: "https://commercial.washingtonfederal.com/smallbusiness/auth",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-bb-treasury-prime",
              externalLink: true,
              show: true,
              text: "Treasury Prime Login",
              url: "https://commercial.washingtonfederal.com",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-bb-banking-officer",
              show: true,
              text: "Find a Banking Officer",
              url: "/business-banking/near-me",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            },
            {
              id: "mml-bb-business-rates",
              show: true,
              text: "Business Rates",
              url: "/business-banking/business-cd-money-market-rates",
              icon: {
                position: "left",
                lib: "fas",
                name: "chevron-right",
                class: "mt-1 mr-2"
              }
            }
          ]
        }
      ]
    },

    // COMMERCIAL BANKING
    {
      id: "mm-cb-dropdown",
      labelId: "mm-cb-dropdown-label",
      title: "Commercial",
      landingTitle: "WaFd Bank Commercial Banking",
      landingUrl: "/commercial-banking",
      showScrollIcon: true,
      columns: [
        {
          id: "mmc-cb-commercial-lending",
          show: true,
          type: "links",
          class: "",
          title: "Commercial Lending",
          url: "/commercial-banking/commercial-lending",
          menus: [
            {
              id: "mml-cb-commercial-lending-overview",
              show: false,
              text: "Lending Overview",
              url: "/commercial-banking/commercial-lending"
            },
            {
              id: "mml-cb-line-of-credit",
              show: true,
              text: "Commercial Line of Credit",
              url: "/commercial-banking/commercial-line-of-credit"
            },
            {
              id: "mml-cb-equipment-financing",
              show: true,
              text: "Equipment Finance",
              url: "/commercial-banking/business-equipment-financing"
            },
            {
              id: "mml-cb-acquisition-financing",
              show: true,
              text: "Acquisition Financing",
              url: "/commercial-banking/acquisition-financing"
            },
            {
              id: "mml-cb-real-estate-financing",
              show: true,
              text: "Owner Occupied Real Estate",
              url: "/commercial-banking/owner-occupied-real-estate-financing"
            },
            {
              id: "mml-cb-term-loans",
              show: true,
              text: "Commercial Term Loans",
              url: "/commercial-banking/commercial-term-loans"
            },
            {
              id: "mml-cb-local-commercial-team",
              show: true,
              text: "Local Commercial Team",
              url: "/commercial-banking#local-commercial-team"
            }
          ]
        },
        {
          id: "mmc-cb-real-estate",
          show: true,
          type: "links",
          class: "",
          title: "Commercial Real Estate",
          url: "/commercial-banking/commercial-real-estate-loans",
          menus: [
            {
              id: "mml-cb-real-estate-loans",
              show: true,
              text: "Commercial Real Estate",
              url: "/commercial-banking/commercial-real-estate-loans"
            },
            {
              id: "mml-cb-multifamily-and-self-storage-loans",
              show: true,
              text: "Community Real Estate Lending",
              url: "/commercial-banking/multifamily-self-storage-loans"
            },
            {
              id: "mml-cb-real-estate-products-services",
              show: true,
              text: "Products and Services",
              url: "/commercial-banking/commercial-real-estate-products-and-services"
            },
            {
              id: "mml-cb-wafd-client-connect",
              show: true,
              text: "WAFD Client Connect",
              url: "/commercial-banking/commercial-client-connect"
            }
          ]
        },
        {
          id: "mmc-cb-treasury-management",
          show: true,
          type: "links",
          class: "",
          title: "Treasury Management",
          url: "/commercial-banking/treasury-management-services",
          menus: [
            {
              id: "mml-cb-treasury-management-services",
              show: false,
              text: "Overview",
              url: "/commercial-banking/treasury-management-services"
            },
            {
              id: "mml-bb-commercial-credit-card",
              show: true,
              text: "Commercial Credit Card",
              url: "/commercial-banking/commercial-credit-card"
            },
            {
              id: "mml-bb-business-zelle",
              show: true,
              text: "Zelle",
              url: "/business-banking/online-banking/zelle"
            },
            {
              id: "mml-cb-deposit-accounts",
              show: true,
              text: "Deposit Accounts",
              url: "/commercial-banking/treasury-management-services/deposit-accounts"
            },
            {
              id: "mml-cb-security-fraud",
              show: true,
              text: "Security & Fraud",
              url: "/commercial-banking/treasury-management-services/security-fraud"
            },
            {
              id: "mml-cb-treasury-express",
              show: true,
              text: "WAFD Treasury Express",
              url: "/commercial-banking/treasury-management-services/express"
            },
            {
              id: "mml-cb-treasury-prime",
              show: true,
              text: "WAFD Treasury Prime",
              url: "/commercial-banking/treasury-management-services/prime"
            },
            {
              id: "mml-cb-receivables",
              show: true,
              text: "Receivables",
              url: "/commercial-banking/treasury-management-services/receivables"
            },
            {
              id: "mml-cb-payables",
              show: true,
              text: "Payables",
              url: "/commercial-banking/treasury-management-services/payables"
            }
          ]
        },
        {
          id: "mmc-cb-specialized-industries",
          show: true,
          type: "links",
          class: "",
          title: "Specialized Industries",
          url: "/commercial-banking/specialized-industries",
          menus: [
            {
              id: "mml-cb-specialized-industries-overview",
              show: false,
              text: "Specialized Industries",
              url: "/commercial-banking/specialized-industries"
            },
            {
              id: "mml-cb-government-banking",
              show: true,
              text: "Government & Institutional Banking",
              url: "/commercial-banking/specialized-industries/institutional-government-banking"
            },
            {
              id: "mml-cb-association-banking",
              show: false,
              text: "Homeowner Association Banking",
              url: "/commercial-banking/specialized-industries/association-banking"
            },
            {
              id: "mml-cb-agribusiness-banking",
              show: true,
              text: "Agribusiness",
              url: "/commercial-banking/specialized-industries/agribusiness-banking"
            },
            {
              id: "mml-cb-senior-housing",
              show: true,
              text: "Senior Housing",
              url: "/commercial-banking/specialized-industries/senior-housing"
            },
            {
              id: "mml-cb-professional-service-firms",
              show: true,
              text: "Professional Service Firms",
              url: "/commercial-banking/specialized-industries/professional-service-firms"
            }
          ]
        }
      ]
    },

    // WAFD MONEY TIPS (BLOG ARTICLES)
    {
      id: "mm-ap-dropdown",
      labelId: "mm-ap-dropdown-label",
      title: "Money Tips",
      landingTitle: "WaFd Money Tips",
      landingUrl: "/blog",
      showScrollIcon: false,
      columns: [
        {
          id: "mmc-ap-article-topics",
          show: true,
          type: "links",
          class: "",
          title: "",
          url: "/commercial-banking/commercial-lending",
          menus: [
            {
              id: "mml-ap-banking-101",
              show: true,
              text: "Banking 101",
              url: "/blog/banking-101"
            },
            {
              id: "mml-ap-building-remodeling",
              show: true,
              text: "Building & Remodeling",
              url: "/blog/building-remodeling"
            },
            {
              id: "mml-ap-building-credit",
              show: true,
              text: "Building Credit",
              url: "/blog/building-credit"
            },
            {
              id: "mml-ap-community-support",
              show: true,
              text: "Community Support",
              url: "/blog/community-support"
            },
            {
              id: "mml-ap-consumer-tips",
              show: true,
              text: "Consumer Tips",
              url: "/blog/consumer-tips"
            },
            {
              id: "mml-ap-family-finance",
              show: true,
              text: "Family Finance",
              url: "/blog/family-finance"
            },
            {
              id: "mml-ap-home-ownership",
              show: true,
              text: "Home Ownership",
              url: "/blog/home-ownership"
            },
            {
              id: "mml-ap-security-privacy",
              show: true,
              text: "Security & Privacy",
              url: "/blog/security-privacy"
            },
            {
              id: "mml-ap-small-business",
              show: true,
              text: "Small Business",
              url: "/blog/small-business"
            }
          ]
        },
        {
          id: "mmc-ap-popular-article-01",
          show: true,
          type: "image",
          class: "",
          title: "",
          text: "Protect Your Bank Accounts",
          url: "/blog/security-privacy/protect-your-bank-accounts",
          menus: [],
          image: {
            id: "mmc-ap-popular-article-01-image",
            show: true,
            class: "card-img-top",
            imgVariable: "popularArticle01ImgVar",
            fixed: true,
            altText: "Woman entering secure password on smartphone, secure icon above."
          },
          video: { show: false },
          inputs: [],
          popularArticleUrl: "/blog/security-privacy/protect-your-bank-accounts"
        },
        {
          id: "mmc-ap-popular-article-02",
          show: true,
          type: "image",
          class: "",
          title: "",
          text: "HELOAN vs HELOC: Pros and Cons",
          url: "/blog/home-ownership/heloc-vs-heloan-pros-cons",
          menus: [],
          image: {
            id: "mmc-ap-popular-article-02-image",
            show: true,
            class: "card-img-top",
            imgVariable: "popularArticle02ImgVar",
            fixed: true,
            altText: "A happy couple standing in a glass doorway admiring their yard."
          },
          video: { show: false },
          inputs: [],
          popularArticleUrl: "/blog/home-ownership/heloc-vs-heloan-pros-cons"
        },
        {
          id: "mmc-ap-popular-article-03",
          show: true,
          type: "image",
          class: "",
          title: "",
          text: "How to Step Up Your Small Business Growth",
          url: "/blog/small-business/how-to-grow-small-business",
          menus: [],
          image: {
            id: "mmc-ap-popular-article-03-image",
            show: true,
            class: "card-img-top",
            imgVariable: "popularArticle03ImgVar",
            fixed: true,
            altText: "Businesswoman in a warehouse, working on a laptop."
          },
          video: { show: false },
          inputs: [],
          popularArticleUrl: "/blog/small-business/how-to-grow-small-business"
        }
      ]
    }
  ];

module.exports = {
  englishAboutUsLinks,
  englishMegaMenusData
};
