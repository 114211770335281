import React, { useEffect, useRef, useState } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";

import styles from "./search-suggestions-input.module.scss";
import stylesWidget from "./search-suggestions-widget.module.scss";

import Button from "./button";
import NotificationAlert from "../notifications/notification-alert";

// Font Awesome Icons
import Icon from "./icon";

import useMediaQuery from "../../hooks/use-media-query";
import { Form, FormGroup } from "react-bootstrap";

// Null import when ssr build is done, it fixes the module error trying to access window object.
const AddSearchClient = typeof window !== `undefined` ? require("addsearch-js-client") : null;

const getTextSuggestionsComponent = (maxLength, suggestionValue, searchedValue) => {
  let slicedText =
    suggestionValue.replaceAll(" | WaFd Bank", "").length > maxLength
      ? suggestionValue
          .replaceAll(" | WaFd Bank", "")
          .slice(0, maxLength + 1)
          .toLowerCase() + "..."
      : suggestionValue.replaceAll(" | WaFd Bank", "").toLowerCase();

  // console.log("EL SLICED TEXT: " + slicedText + " | " + slicedText.length);

  if (suggestionValue.replaceAll(" | WaFd Bank", "").toLowerCase().includes(searchedValue.toLowerCase())) {
    if (
      suggestionValue.replaceAll(" | WaFd Bank", "").toLowerCase() === searchedValue.toLowerCase() ||
      searchedValue.length > maxLength
    ) {
      return searchedValue.replaceAll(" | WaFd Bank", "").length > maxLength
        ? searchedValue.slice(0, maxLength + 1) + "..."
        : searchedValue;
    }

    if (slicedText.includes(searchedValue.toLowerCase())) {
      let splittedValue = slicedText.slice(0, slicedText.indexOf(searchedValue));
      let afterMatchValue = slicedText.slice(slicedText.indexOf(searchedValue) + searchedValue.length);

      // console.log("EL AFTER MATCH VALUE: " + JSON.stringify(afterMatchValue));
      let lightText1 = splittedValue;
      let lightText2 = afterMatchValue;
      let lightTextStartFirstIndex = lightText1.length;

      // console.log("LIGHT TEXT 1: " + lightText1);
      // console.log("LIGHT TEXT 2: " + lightText2);
      let indexOfStart =
        lightText1.length + searchedValue.length === slicedText.length
          ? slicedText.length
          : lightText1.length + searchedValue.length;
      let lightTextStartFinalIndex = slicedText.indexOf(lightText2, indexOfStart);
      let boldText = slicedText.slice(lightTextStartFirstIndex, lightTextStartFinalIndex);
      // console.log("INDEXES: " + slicedText.length + " | " + lightTextStartFirstIndex + " | " + indexOfStart);
      let finalText = (
        <>
          <span style={{ fontWeight: "400" }}>{lightText1}</span>
          <span>{boldText}</span>
          <span style={{ fontWeight: "400" }}>{lightText2}</span>
        </>
      );
      return finalText;
    } else {
      return <span style={{ fontWeight: "400" }}>{slicedText}</span>;
    }
  } else {
    return <span style={{ fontWeight: "400" }}>{slicedText}</span>;
  }
};

const SearchSuggestionsInput = (props) => {
  const {
    type,
    query,
    setQuery,
    querySuggestions,
    setQuerySuggestions,
    handleCloseSearchModal,
    showMobileSearch,
    setShowMobileSearch,
    handleCloseMobileSearch,
    search,
    setSearch,
    setSelectBankers
  } = props;

  const [queryInputFocused, setQueryInputFocused] = useState(false);
  const [searchType, setSearchType] = useState(0); // 0 For main search type and 1 for secondary search type
  const searchFormContainerRef = useRef(null);
  const searchRef = useRef(null);

  const addSearchCallback = (res) => {
    // console.log("THE RESULTS: ", res.hits);
    setQuerySuggestions({
      ...res,
      hits: res.hits.filter((element) => element.title != null)
    });
  };

  useEffect(() => {
    if (showMobileSearch) {
      searchRef.current.focus();
    }
  }, [showMobileSearch]);

  useEffect(() => {
    if (type !== "widget" && type !== "select-banking" && type !== "mobile") {
      searchRef.current.focus();
    } else {
      setQueryInputFocused(false);
    }
  }, []);

  useEffect(() => {
    function handler(event) {
      if (!searchFormContainerRef.current?.contains(event.target)) {
        setQueryInputFocused(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  });

  const BRANCH_STATES_AND_SELECT_BANKERS = useStaticQuery(graphql`
    query {
      allStrapiBranchStates(sort: { order: ASC, fields: StateName }) {
        edges {
          node {
            id
            StateName
            StateCode
          }
        }
      }
      allStrapiSelectBankers {
        edges {
          node {
            id
            State
            Name
            PhoneNumber
            EmailAddress
            ProfilePhoto {
              childImageSharp {
                id
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  // TODO: why are we using edges[0].node instead of node[0]?
  const handleRadioChange = (e) => {
    setSelectBankers([]);
    searchRef.current?.focus();
    e.target.blur();
    if (searchType === 1) {
      let finalState =
        geoState != null ? geoState : BRANCH_STATES_AND_SELECT_BANKERS.allStrapiBranchStates.edges[0].node.StateName;
      setSearchType(0);
      setQueryInputFocused(false);
      setQuery(finalState);
      setSearch(finalState);
      searchSelectBankers(finalState, 0);
    } else {
      setSearchType(1);
      setQueryInputFocused(true);
      setQuery("");
      setSearch("");
    }
  };

  let [error, setError] = useState(null);

  const buildErrorContainer = function () {
    if (error !== null) {
      return <NotificationAlert bodyText={error} type="danger" />;
    }
  };

  const [geoState, setGeoState] = useState(null);
  const [isUtagJsLoaded, setIsUtagJsLoaded] = useState(false);

  useEffect(() => {
    addSearchClient.setCollectAnalytics(false);
    if (type === "select-banking") {
      function handleUtagScriptLoad() {
        if (typeof window?.utag?.view === "function") {
          setIsUtagJsLoaded(true);
        }
      }
      const utagScript = document.getElementById("utag-script");
      utagScript.addEventListener("load", handleUtagScriptLoad);
      return () => utagScript.removeEventListener("load", handleUtagScriptLoad);
    }
  }, []);

  useEffect(() => {
    if (type === "select-banking") {
      if (!isUtagJsLoaded) return null;

      let script = document.createElement("script");

      script.id = "tealium-utag-view-gatsby";

      document.body.appendChild(script);

      let searchState = window.utag.data.geo_state != null ? window.utag.data.geo_state.toLowerCase() : "";

      // let validStateFound = validStates.find((state) => state === searchState);
      let validStateFound = BRANCH_STATES_AND_SELECT_BANKERS.allStrapiBranchStates.edges.find(
        (state) => state.node.StateCode?.toLowerCase() === searchState.toLowerCase()
      );
      let finalState;
      if (validStateFound != null) {
        setGeoState(validStateFound.node.StateName);
        finalState = validStateFound.node.StateName;
      } else {
        finalState = BRANCH_STATES_AND_SELECT_BANKERS.allStrapiBranchStates.edges[0].node.StateName;
      }
      setQuery(finalState);
      setSearch(finalState);
      searchSelectBankers(finalState, searchType);

      // cleanup on page tear down
      return (el) => {
        document.body.removeChild(script);
      };
    }
  }, [isUtagJsLoaded]);

  const searchSelectBankersSuggestions = (searchValue) => {
    let queryPro = BRANCH_STATES_AND_SELECT_BANKERS.allStrapiSelectBankers.edges.filter((banker) =>
      banker.node.Name.toLowerCase().includes(searchValue.toLowerCase())
    );

    return queryPro;
  };

  const searchSelectBankers = (searchValue, searchTypePro) => {
    // console.log("THE SV: " + searchValue + "\n\n" + searchTypePro);
    if (searchTypePro === 0) {
      let queryPro = [];
      if (searchValue === "NewMexico") {
        queryPro = BRANCH_STATES_AND_SELECT_BANKERS.allStrapiSelectBankers.edges.filter(
          (banker) =>
            banker.node.State.toLowerCase().includes("newmexico") ||
            banker.node.State.toLowerCase().includes("new mexico")
        );
      } else {
        queryPro = BRANCH_STATES_AND_SELECT_BANKERS.allStrapiSelectBankers.edges.filter((banker) =>
          banker.node.State.toLowerCase().includes(searchValue.toLowerCase())
        );
      }

      setError(
        queryPro.length > 0
          ? null
          : 'This search returned 0 results. For regions without a designated Select Banker, reach out to <a href="mailto:selectbanking@wafd.com" id="error-select-banking-email">selectbanking@wafd.com</a>'
      );
      setSelectBankers(queryPro);
    } else {
      let queryPro = BRANCH_STATES_AND_SELECT_BANKERS.allStrapiSelectBankers.edges.filter((banker) =>
        banker.node.Name.toLowerCase().includes(searchValue.toLowerCase())
      );

      setError(queryPro.length > 0 ? null : "This search returned 0 results. Try rewording your search.");
      setSelectBankers(queryPro);
    }
  };

  const maxXs = `only screen and (max-width: 399px)`;
  const maxPhone = `only screen and (max-width: 599px)`;
  const maxTablet = `only screen and (max-width: 992px)`;

  const xsMediaQueryMatch = useMediaQuery(maxXs);
  const phoneMediaQueryMatch = useMediaQuery(maxPhone);
  const tabletMediaQueryMatch = useMediaQuery(maxTablet);

  const PUBLIC_API_TOKEN = "32c2d1fdc8fe47dfd59c772e4a8a9263";
  // Null client when SSR build is done, it fixes the error coming from using a null import
  const addSearchClient = AddSearchClient ? new AddSearchClient(PUBLIC_API_TOKEN) : null;

  return type === "modal" || type === "mobile" ? (
    <>
      <form autoComplete="off">
        <div className={`row ${type === "mobile" ? "mx-1" : ""}`}>
          {type === "mobile" && (
            <button
              id="ssi-hide-mobile-search-button"
              onClick={(e) => {
                e.preventDefault();
                setShowMobileSearch(false);
                setQuerySuggestions({ hits: [] });
                setQuery("");
              }}
              className={`pl-0 pr-2 d-inline-block ${styles.buttonHideMobileSearch}`}
            >
              <Icon name="arrow-left" class="fa-md text-gray-90" />
            </button>
          )}

          <div className={`${styles.searchInputContainer} ${type === "mobile" ? "ml-0" : ""} d-flex flex-grow-1`}>
            <input
              id={"ssi-search-input" + (type === "mobile" ? "-mobile" : "")}
              type="text"
              className={`${styles.searchInput} w-100 ml-2 py-0 rounded-0 border-0`}
              style={{ outline: "none" }}
              aria-label="Search WaFd Bank"
              ref={searchRef}
              aria-describedby="search-input"
              value={query}
              onChange={(event) => {
                setQuery(event.target.value);
                if (event.target.value.length > 0) {
                  // addSearchClient.setSuggestionsSize(ITEMS_PER_PAGE);
                  addSearchClient.search(event.target.value, addSearchCallback);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  if (showMobileSearch) {
                    handleCloseMobileSearch(false);
                  } else {
                    handleCloseSearchModal();
                  }
                  if (query.length > 0) {
                    navigate(`/customer-service/search?addsearch=${query}`);
                    addSearchClient.sendStatsEvent("search", query, { numberOfResults: 1 });
                    setQuery("");
                    setQuerySuggestions({ hits: [] });
                  }
                }
              }}
              label="Search WaFd Bank"
            />
            <button
              id={"ssi-clear-text-button" + (type === "mobile" ? "-mobile" : "")}
              onClick={(e) => {
                e.preventDefault();
                searchRef.current.focus();
                setQuery("");
              }}
              className={`py-2 d-inline-block ${styles.buttonClearText}`}
            >
              <Icon name="times" class="fa-md text-secondary" />
            </button>
          </div>
          <button
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              if (query !== search) {
                if (query !== "") {
                  handleCloseSearchModal();
                  addSearchClient.sendStatsEvent("search", query, { numberOfResults: 1 });
                  navigate(`/customer-service/search?addsearch=${query}`);
                }
              }
            }}
            className={`btn btn-primary no-min-width ${styles.searchInputButton} ${type === "mobile" ? "mr-0" : ""}`}
            id={"ssi-search-button" + (type === "mobile" ? "-mobile" : "")}
          >
            <Icon name="search" class="fa-lg" />
            <span className="sr-only">Search</span>
          </button>
        </div>
      </form>
      {query !== "" ? (
        querySuggestions.hits.length > 0 ? (
          querySuggestions.hits.map((suggestion, index) => (
            <div
              key={index}
              className={`row d-flex ${type === "mobile" ? "ml-4 mr-1" : ""} ${
                type === "mobile"
                  ? index === 0
                    ? index === querySuggestions.hits.length - 1
                      ? "my-2"
                      : "mt-2"
                    : index === querySuggestions.hits.length - 1
                    ? "mb-2"
                    : ""
                  : index === 0
                  ? "mt-2"
                  : ""
              }`}
            >
              <Button
                id={`ssi-suggestion-icon-button-${index}`}
                type="button"
                onClick={(event) => {
                  setQuery(suggestion.title.replaceAll(" | WaFd Bank", "").toLowerCase());
                  // addSearchClient.setSuggestionsSize(ITEMS_PER_PAGE);
                  addSearchClient.search(suggestion.title.replaceAll(" | WaFd Bank", ""), addSearchCallback);
                }}
                icon={{
                  name: "search",
                  class: "fa-lg"
                }}
                class={`bg-white ${type === "mobile" ? "no-min-width w-auto px-0" : styles.btnSuggestionExtraIcon}`}
                containerClass="w-auto"
              />
              <Button
                id={`ssi-suggestion-button-${index}`}
                type="button"
                text={getTextSuggestionsComponent(
                  type === "mobile" ? (phoneMediaQueryMatch ? 22 : 30) : 45,
                  suggestion.title,
                  query
                )}
                onClick={(event) => {
                  if (showMobileSearch) {
                    handleCloseMobileSearch(false);
                  } else {
                    handleCloseSearchModal();
                  }
                  let suggestionText = suggestion.title.replaceAll(" | WaFd Bank", "").toLowerCase();
                  setQuery(suggestionText);
                  addSearchClient.search(suggestionText, () => {});
                  setQuerySuggestions({ hits: [] });
                  navigate(`/customer-service/search?addsearch=${suggestionText}`);

                  addSearchClient.sendStatsEvent("search", suggestionText, { numberOfResults: 1 });
                  addSearchClient.sendStatsEvent("click", suggestionText, {
                    documentId: suggestion.id,
                    position: index
                  });
                  // console.log("EL TEXT: " + suggestionText);
                }}
                showIcon={true}
                icon={{
                  name: "arrow-right",
                  lib: "fal",
                  class: `fa-lg float-right ${styles.btnSuggestionIcon}`
                }}
                class={`${type === "mobile" ? "pl-3 mr-0" : ""} ${
                  type === "modal" ? stylesWidget.btnSuggestion : styles.btnSuggestion
                }`}
                containerClass="d-flex flex-grow-1"
              />
            </div>
          ))
        ) : (
          <div className="input-group mt-2 mx-3">
            <p id="ssi-no-results-found-message" className={`h6 ${type === "mobile" ? "mb-2" : "mb-0"}`}>
              No results found
            </p>
          </div>
        )
      ) : null}
    </>
  ) : type === "widget" ? (
    <div className="row position-relative">
      <div className={`${queryInputFocused ? "d-block" : "d-none"} ${stylesWidget.placeholderSearchInputContainer}`} />
      <div
        ref={searchFormContainerRef}
        className={`col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 ${
          queryInputFocused ? stylesWidget.searchFormContainerFocused : stylesWidget.searchFormContainerBlured
        } border-radius-6`}
      >
        <p className="mb-0 mt-2 h5 font-weight-bold" style={{ paddingLeft: 3 }}>
          Search WaFd Bank
        </p>
        <form className="row" autoComplete="off">
          <div className={`col-12 row my-2 ${query === "" && "mb-3"} mx-0 px-0`}>
            <div className={`${stylesWidget.searchInputContainer} d-flex flex-grow-1`}>
              <input
                id="ssi-search-input"
                type="text"
                className={`${stylesWidget.searchInput} w-100 ml-2 py-0 rounded-0 border-0`}
                style={{ outline: "none" }}
                aria-label="Search WaFd Bank"
                ref={searchRef}
                aria-describedby="search-input"
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);
                  setQueryInputFocused(true);
                  if (event.target.value.length > 0) {
                    // addSearchClient.setSuggestionsSize(SUGGESTIONS_PER_PAGE);
                    addSearchClient.search(event.target.value, addSearchCallback);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && query.length > 0) {
                    event.preventDefault();
                    if (query !== search) {
                      setSearch(query);
                      setQueryInputFocused(false);
                      addSearchClient.sendStatsEvent("search", query, { numberOfResults: 1 });
                    }
                  }
                }}
                onFocus={() => setQueryInputFocused(true)}
                label="Search WaFd Bank"
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  searchRef.current.focus();
                  setQuery("");
                }}
                className={`py-2 d-inline-block ${stylesWidget.buttonClearText}`}
              >
                <Icon name="times" class="fa-md text-secondary" />
              </button>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                if (query !== search) {
                  if (query !== "") {
                    setSearch(query);
                    setQueryInputFocused(false);

                    addSearchClient.sendStatsEvent("search", query, { numberOfResults: 1 });
                  }
                }
              }}
              className={`${stylesWidget.searchInputButton} btn btn-primary no-min-width`}
              id="ssi-search-button"
            >
              <Icon name="search" class="fa-lg" />
              <span className="sr-only">Search</span>
            </button>
          </div>
        </form>
        <div className={`col-12 px-0 ${stylesWidget.suggestionsContainer}`}>
          {queryInputFocused && query !== "" ? (
            querySuggestions.hits.length > 0 ? (
              <div className="pl-0">
                {querySuggestions.hits.map((suggestion, index) => (
                  <div key={index} className={`row d-flex ${index === querySuggestions.hits.length - 1 ? "mb-2" : ""}`}>
                    <Button
                      type="button"
                      onClick={() => {
                        setQuery(suggestion.title.replaceAll(" | WaFd Bank", "").toLowerCase());
                        // addSearchClient.setSuggestionsSize(SUGGESTIONS_PER_PAGE);
                        addSearchClient.search(suggestion.title.replaceAll(" | WaFd Bank", ""), addSearchCallback);
                      }}
                      icon={{
                        name: "search",
                        class: "fa-lg"
                      }}
                      class={`b-white ${stylesWidget.btnSuggestionExtraIcon}`}
                      containerClass="w-auto"
                    />
                    <Button
                      type="button"
                      text={getTextSuggestionsComponent(
                        xsMediaQueryMatch ? 25 : phoneMediaQueryMatch ? 33 : tabletMediaQueryMatch ? 44 : 50,
                        suggestion.title == null ? suggestion.url : suggestion.title.replaceAll(" | WaFd Bank", ""),
                        query
                      )}
                      onClick={() => {
                        let suggestionText =
                          suggestion.title == null
                            ? suggestion.url
                            : suggestion.title.replaceAll(" | WaFd Bank", "").toLowerCase();
                        if (suggestionText !== search.replaceAll(" | WaFd Bank", "").toLowerCase()) {
                          setQuery(suggestionText);
                          setSearch(suggestionText);
                          addSearchClient.search(suggestionText, () => {});

                          addSearchClient.sendStatsEvent("search", suggestionText, { numberOfResults: 1 });
                          addSearchClient.sendStatsEvent("click", suggestionText, {
                            documentId: suggestion.id,
                            position: index
                          });
                          // console.log("EL TEXT: " + suggestionText);
                          setQueryInputFocused(false);
                        } else {
                          setQueryInputFocused(false);
                        }
                      }}
                      showIcon={true}
                      icon={{
                        name: "arrow-right",
                        lib: "fal",
                        class: `fa-lg float-right ${stylesWidget.btnSuggestionIcon}`
                      }}
                      class={stylesWidget.btnSuggestion}
                      containerClass="d-flex flex-grow-1"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="input-group mb-2 mx-3">
                <p className="h6 mb-0">No results found</p>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  ) : type === "select-banking" ? (
    <div className="row position-relative">
      <div className="mx-3 w-100">
        <h4 className="mt-2 font-weight-semi-bold" style={{ paddingLeft: 3 }}>
          Search for a Select Banker
        </h4>
        <FormGroup className="row mx-0">
          <Form.Check
            tabIndex={0}
            inline
            label="Search By Location"
            name="principal"
            type={"radio"}
            id={`inline-radio-1`}
            value={0}
            checked={searchType === 0}
            onChange={(e) => handleRadioChange(e)}
          />
          <Form.Check
            tabIndex={0}
            inline
            label="Search By Banker Name"
            name="secondary"
            type={"radio"}
            id={`inline-radio-2`}
            style={{ marginTop: 0, marginRight: 0 }}
            value={1}
            checked={searchType === 1}
            onChange={(e) => handleRadioChange(e)}
          />
        </FormGroup>
        <div className={`col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 px-0 border-radius-6`}>
          {buildErrorContainer()}
        </div>
      </div>
      <div
        className={`${queryInputFocused ? "d-block" : "d-none"} ${
          error == null
            ? stylesWidget.placeholderSearchSelectBankingInputContainer
            : stylesWidget.placeholderSearchSelectBankingInputContainerError
        }`}
      />
      <div
        ref={searchFormContainerRef}
        className={`col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 ${
          queryInputFocused
            ? error == null
              ? stylesWidget.selectBankingFormContainerFocused
              : stylesWidget.selectBankingFormContainerFocusedError
            : stylesWidget.searchFormContainerBlurred
        } border-radius-6`}
      >
        <div className="row mx-0 justify-content-between">
          <p className="mb-0 mt-2 text-gray-60" style={{ paddingLeft: 3 }}>
            {searchType === 0 ? "State" : "Banker Name"}
          </p>
        </div>
        {searchType === 0 ? (
          <Form.Group id="state-select" className="dropdown">
            <Form.Control
              id="select-state-to-search"
              className="border-radius-6 select-input-text custom-select-chevron"
              tabIndex={0}
              as="select"
              defaultValue={""}
              value={query}
              onChange={(e) => {
                if (e.target.value !== search) {
                  setQuery(e.target.value);
                  setSearch(e.target.value);
                  searchSelectBankers(e.target.value, searchType);
                }
              }}
            >
              {BRANCH_STATES_AND_SELECT_BANKERS.allStrapiBranchStates.edges.map((value, index) => (
                <option
                  key={index}
                  value={value.node.StateName !== "New Mexico" ? value.node.StateName : "NewMexico"}
                  id={value.node.id}
                  className="dropdown-content"
                >
                  {value.node.StateName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        ) : (
          <>
            <form className="row" autoComplete="off">
              <div className={`col-12 row my-2 ${query === "" ? "mb-3" : !queryInputFocused ? "mb-3" : ""} mx-0 px-0`}>
                <div className={`${stylesWidget.searchInputContainer} d-flex flex-grow-1`}>
                  <input
                    id="ssi-search-input"
                    type="text"
                    className={`${stylesWidget.searchInput} w-100 ml-2 py-0 rounded-0 border-0`}
                    style={{ outline: "none" }}
                    tabIndex={0}
                    autoComplete="off"
                    aria-label="Search WaFd Bank"
                    ref={searchRef}
                    aria-describedby="search-input"
                    value={query}
                    onChange={(event) => {
                      setQuery(event.target.value);
                      setQueryInputFocused(true);
                      if (event.target.value.length > 0) {
                        setQuerySuggestions({
                          hits: searchSelectBankersSuggestions(event.target.value)
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && query.length > 0) {
                        event.preventDefault();
                        if (query !== search) {
                          setSearch(query);
                          setQueryInputFocused(false);
                          searchSelectBankers(query, searchType);
                        }
                      }
                    }}
                    onFocus={(event) => {
                      if (event.target.value.length > 0) {
                        setQuerySuggestions({
                          hits: searchSelectBankersSuggestions(event.target.value)
                        });
                      }
                      setQueryInputFocused(true);
                    }}
                    label="Search WaFd Bank"
                  />
                  <button
                    tabIndex={0}
                    onClick={(e) => {
                      e.preventDefault();
                      searchRef.current.focus();
                      setQuery("");
                    }}
                    className={`py-2 d-inline-block ${stylesWidget.buttonClearText}`}
                  >
                    <Icon name="times" class="fa-md text-secondary" />
                  </button>
                </div>
                <button
                  tabIndex={0}
                  onClick={(e) => {
                    e.preventDefault();
                    if (query !== search) {
                      if (query !== "") {
                        setSearch(query);
                        addSearchClient.sendStatsEvent("search", query, { numberOfResults: 1 });
                        setQueryInputFocused(false);
                        searchSelectBankers(query, searchType);
                      }
                    }
                  }}
                  className={`${stylesWidget.searchInputButton} btn btn-primary no-min-width`}
                  id="ssi-search-button"
                >
                  <p className="mb-0 font-weight-bold">Go</p>
                  <span className="sr-only">Search</span>
                </button>
              </div>
            </form>
            <div className={`col-12 px-0 ${stylesWidget.suggestionsContainer}`}>
              {query !== "" ? (
                querySuggestions.hits.length > 0 ? (
                  <div className="pl-0">
                    {querySuggestions.hits.map((suggestion, index) => (
                      <div
                        key={index}
                        className={`row d-flex ${index === querySuggestions.hits.length - 1 ? "mb-2" : ""}`}
                      >
                        <Button
                          type="button"
                          onClick={() => {
                            setQuery(suggestion.node.Name);
                            setQuerySuggestions({
                              hits: searchSelectBankersSuggestions(suggestion.node.Name)
                            });
                          }}
                          icon={{
                            name: "search",
                            class: "fa-lg"
                          }}
                          class={`b-white ${stylesWidget.btnSuggestionExtraIcon}`}
                          containerClass="w-auto"
                        />
                        <Button
                          type="button"
                          text={getTextSuggestionsComponent(
                            xsMediaQueryMatch ? 25 : phoneMediaQueryMatch ? 33 : tabletMediaQueryMatch ? 44 : 50,
                            suggestion.node.Name,
                            query
                          )}
                          onClick={() => {
                            if (suggestion.node.Name.toLowerCase() !== search.toLowerCase()) {
                              setQuery(suggestion.node.Name);
                              setSearch(suggestion.node.Name);
                              setQueryInputFocused(false);
                              searchSelectBankers(suggestion.node.Name, searchType);
                            } else {
                              setQueryInputFocused(false);
                            }
                          }}
                          showIcon={true}
                          icon={{
                            name: "arrow-right",
                            lib: "fal",
                            class: `fa-lg float-right ${stylesWidget.btnSuggestionIcon}`
                          }}
                          class={`${stylesWidget.btnSuggestion} text-capitalize`}
                          containerClass="d-flex flex-grow-1"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="input-group mb-2 mx-3">
                    <p className="h6 mb-0">No results found</p>
                  </div>
                )
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default SearchSuggestionsInput;
