import React from "react";
import { Link } from "gatsby";
import { useLanguageContext } from "../../contexts/language-context";

// Components
import FooterList from "./footer-list";
import MobileFooter from "./footer-mobile";
import FooterBottom from "./footer-bottom";

// Data
import { footerLinksEnglish, socialLinksEnglish } from "../../data/footer-data";
import { footerLinksSpanish, socialLinksSpanish } from "../../data/footer-data-spanish";

// Images
import Logo from "../../assets/wafd-bank-logo.svg";

// Styles
import styles from "./footer.module.scss";

// Icons
import Icon from "../custom-widgets/icon";

const Footer = (props) => {
  const isSpanish = useLanguageContext();
  //console.log("footer isSpanish: ", isSpanish);
  const footerLinks = isSpanish ? footerLinksSpanish : footerLinksEnglish;
  const socialLinks = isSpanish ? socialLinksSpanish : socialLinksEnglish;
  const footerColumnClass = isSpanish ? "col-md-6" : "col-md-6 col-lg-3";

  return (
    <footer id="footer" className={props.footerBorder ? "border-top" : ""}>
      <div id="footer-top" className="container py-3 my-3 border-bottom d-none d-md-block ">
        <div className="row">
          {/* SITE MAP LINKS */}
          <div className="col-sm-7 col-md-8 col-lg-9">
            <div className="row no-gutters">
              {footerLinks.map((footerCol) => (
                <div key={footerCol.id} className={footerColumnClass}>
                  <FooterList {...footerCol} />
                </div>
              ))}
            </div>
          </div>

          {/* CONTACT/BANK INFO */}
          <div className="col-sm-5 col-md-4 col-lg-3">
            <Link to={isSpanish ? "/es" : "/"}>
              <img src={Logo} width={200} height={55} className="mb-4" alt="WaFd Bank logo" />
            </Link>
            <div className="row">
              <div className="col-md-2">
                <Icon lib="fal" name="phone-alt" fixedWidth="2rem" class="mr-2 fa-lg" />
              </div>
              <div className="col-md-10">
                <p className="lead">
                  <a href="tel:800-324-9375">800-324-9375</a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <Icon lib="fal" name="map-marker-alt" fixedWidth="2rem" class="mr-2 fa-lg" />
              </div>
              <div className="col-md-10 mb-4">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://maps.google.com/maps?daddr=425%20Pike%20Street%2cSeattle%2cWA%2c98101"
                >
                  425 Pike Street
                  <br />
                  Seattle, WA 98101
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <Icon lib="fal" name="arrows-alt" fixedWidth="2rem" class="mr-2 fa-lg" />
              </div>
              <div className="col-md-10">
                <p>Routing # 325070980</p>
              </div>
            </div>

            {/* SOCIAL ICONS */}
            <p className="d-flex align-items-center justify-content-between">
              {socialLinks.map((item) => (
                <a key={item.id} id={`${item.id}-d`} href={item.url} rel="noopener noreferrer" target="_blank">
                  <span className={styles.iconWrapper}>
                    {item.icon.name === "xtwitter" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="text-white"
                        style={{ height: "1.5rem", width: "1.5rem" }}
                        title="X (Twitter)"
                      >
                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                      </svg>
                    ) : (
                      <Icon {...item.icon} />
                    )}
                    <span className="sr-only">{item.icon.title}</span>
                  </span>
                </a>
              ))}
            </p>
          </div>
        </div>
      </div>
      {/* end #footer-top */}

      <FooterBottom />

      <MobileFooter isSpanish={isSpanish} socialLinks={socialLinks} />
    </footer>
  );
};

export default Footer;

Footer.defaultProps = {
  footerBorder: true
};
