import React, { useEffect, useState, Fragment } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

import { useRef } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import Icon from "../custom-widgets/icon";

// Data
import { englishAboutUsLinks, englishMegaMenusData } from "../../data/mega-menus-data";
import { spanishMegaMenusData } from "../../data/mega-menus-spanish-data";

// Styles
import "./mega-menus.bootstrap.scss";

const MegaMenus = () => {
  const menuRef = useRef();
  const megaMenuRef = useRef();

  // This path is to compare the page url and the urls from the megaMenusData
  let path = typeof window !== "undefined" ? window.location.href.replace(window.location.origin, "") : "";
  let hash = useLocation().hash;
  let i = path.indexOf("#");
  if (hash.includes("faq-answer")) {
    path = path.substring(0, i);
    // console.log("removed hash from path: " + path);
  }
  // Remove trailing slash (/) from the path if it exists
  path = path.replace(/\/$/, "");

  // This path is to determine if the page is spanish page.
  // We are adding a trailing slash here so this can't be used to find the exact page URL
  let pagePath = useLocation().pathname;
  // Remove trailing slash (/) from the URL if it exists
  pagePath = pagePath.replace(/\/$/, "");
  // Now explicitly add a trailing slash to act as a word break for ".../es..."
  pagePath = pagePath + "/";

  const pathArray = pagePath.split("/");

  const isSpanish = pagePath.indexOf("/es/") >= 0 ? true : false;

  let [megaMenusData, setMegaMenusData] = useState(isSpanish ? spanishMegaMenusData : englishMegaMenusData);

  let [aboutUsLinks, setAboutUsLinks] = useState(isSpanish ? [] : englishAboutUsLinks);

  let [ddActiveKey, setDdActiveKey] = useState(null);

  useEffect(() => {
    if (pathArray[1] === "personal-banking" || pathArray[2] === "banca-personal") {
      setDdActiveKey("mm-pb-dropdown");
    } else if (pathArray[1] === "business-banking" || pathArray[2] === "banca-de-negocios") {
      setDdActiveKey("mm-bb-dropdown");
    } else if (pathArray[1] === "commercial-banking") {
      setDdActiveKey("mm-cb-dropdown");
    } else if (pathArray[1] === "blog" || pathArray[2] === "tu-decides") {
      setDdActiveKey("mm-ap-dropdown");
    } else {
      setDdActiveKey(null);
    }

    const newMenuData = [...megaMenusData];
    const newAboutUsLinks = [...aboutUsLinks];

    const pathWithoutHash = path.split("#")[0];
    // console.log("PATH:", pathWithoutHash);
    newMenuData.forEach((dropdown) => {
      if (dropdown.landingUrl === pathWithoutHash) {
        dropdown.isActive = true;
      } else {
        dropdown.isActive = false;
      }
      dropdown.columns.forEach((column) => {
        if (column.url === pathWithoutHash) {
          column.isActive = true;
        } else {
          column.isActive = false;
        }
        column.menus.forEach((menu) => {
          if (menu.url === pathWithoutHash) {
            menu.isActive = true;
          } else {
            menu.isActive = false;
          }
        });
      });
    });

    setMegaMenusData(newMenuData);

    newAboutUsLinks.forEach((aboutUsLink) => {
      if (aboutUsLink.url === pathWithoutHash) {
        aboutUsLink.isActive = true;
      } else {
        aboutUsLink.isActive = false;
      }
    });

    setAboutUsLinks(newAboutUsLinks);
  }, [path]);

  // console.log(megaMenusData);

  const imgData = useStaticQuery(graphql`
    {
      popularArticle01ImgVar: file(relativePath: { eq: "mega-menu/thumbnail-protect-bank-account-02092023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      popularArticle02ImgVar: file(relativePath: { eq: "mega-menu/thumbnail-heloan-vs-heloc-pros-cons-072224.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      popularArticle03ImgVar: file(
        relativePath: { eq: "mega-menu/thumbnail-how-to-set-up-your-small-biz-growth-103023.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      placeholderImgVar: file(relativePath: { eq: "mega-menu/image-placeholder-300x200.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const closedIcon = <Icon {...{ name: "chevron-down", lib: "fas", class: "ml-2" }} />; // menu is closed (default state)
  const openIcon = <Icon {...{ name: "chevron-up", lib: "fas", class: "ml-2 text-primary" }} />; // menu is open (hovered/clicked)
  const dropdownLength = megaMenusData ? megaMenusData.length : 0;
  let INITIAL_DROPDOWNS = Array(dropdownLength).fill(false);
  let [click, setClick] = useState(INITIAL_DROPDOWNS);
  let [isMenuOpen, setIsMenuOpen] = useState(false);

  const openClickedMenu = function (index) {
    let newState = [...INITIAL_DROPDOWNS]; // close any open dropdowns by initializing all to false
    setIsMenuOpen(!click[index]);
    newState[index] = !click[index];
    setClick(newState);
  };

  useEffect(() => {
    function handler(event) {
      if (!menuRef.current?.contains(event.target)) {
        setClick(INITIAL_DROPDOWNS);
        setIsMenuOpen(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  const [height, setHeight] = useState(0);
  useEffect(() => {
    setHeight(megaMenuRef.current.clientHeight);
  }, []);

  const megaMenusId = isSpanish ? "mega-menus-spanish" : "mega-menus";
  const megaMenusClass = isSpanish ? "spanish" : null;

  return (
    <>
      <Navbar id={megaMenusId} role="navigation" aria-label="Personal, Business, Commercial" bg="white" expand="lg">
        <Navbar.Toggle aria-controls="primary-navbar-nav" />
        <Navbar.Collapse id="primary-navbar-nav">
          {/* PERSONAL, SMALL BUSINESS, COMMERCIAL, MONEY TIPS DROPDOWNS */}
          <Nav className="mr-auto text-black" activeKey={ddActiveKey} fill={true} justify={true} ref={menuRef}>
            {megaMenusData.map((dropdown, index) => {
              const navDropdownTitle = (
                <>
                  {dropdown.title}
                  {dropdown.columns && dropdown.columns.length > 0 ? (
                    click[index] ? (
                      openIcon
                    ) : (
                      closedIcon
                    )
                  ) : (
                    <Icon name="arrow-right" lib="far" class="ml-2" />
                  )}
                </>
              );
              const activeClass = dropdown.id === ddActiveKey ? "active" : "";
              return (
                <NavDropdown
                  key={dropdown.id}
                  id={dropdown.id}
                  title={navDropdownTitle}
                  className={`w-100 px-0 mr-4 ${activeClass}`}
                  renderMenuOnMount={true}
                  onClick={(e) => {
                    if (dropdown.columns && dropdown.columns.length > 0) {
                      openClickedMenu(index);
                    } else {
                      navigate(dropdown.landingUrl);
                    }
                  }}
                  show={click[index]}
                >
                  {dropdown.columns && dropdown.columns.length > 0 && (
                    <div className="container" ref={megaMenuRef}>
                      {/* LANDING PAGE LINK (TOP ROW) */}
                      {dropdown.landingTitle && dropdown.landingUrl && (
                        <div className="row">
                          <div className="col-auto">
                            <NavDropdown.Item
                              id={dropdown.labelId}
                              className={`row-title ${dropdown.isActive ? "active-menu-item" : ""}`}
                              to={dropdown.landingUrl}
                              as={Link}
                            >
                              {dropdown.landingTitle} <Icon name="arrow-right" lib="fal" class="ml-1 mt-1" />
                            </NavDropdown.Item>
                          </div>
                        </div>
                      )}
                      {/* MAIN CONTENT (MIDDLE ROW) */}
                      <div className="row no-gutters mb-3">
                        {dropdown.columns.map((column, index) => {
                          let popularArticleImgVar = {};
                          if (column.type !== "links" && column.image.show && column.image.imgVariable) {
                            switch (index) {
                              case 1: {
                                popularArticleImgVar = imgData.popularArticle01ImgVar.childImageSharp.gatsbyImageData;
                                break;
                              }
                              case 2: {
                                popularArticleImgVar = imgData.popularArticle02ImgVar.childImageSharp.gatsbyImageData;
                                break;
                              }
                              case 3: {
                                popularArticleImgVar = imgData.popularArticle03ImgVar.childImageSharp.gatsbyImageData;
                                break;
                              }
                              default: {
                                popularArticleImgVar = imgData.placeholderImgVar.childImageSharp.gatsbyImageData;
                              }
                            }
                          }
                          // COLUMN LANDING PAGE LINKS
                          return (
                            <ul
                              key={column.id}
                              className={`col-md ${column.type === "links" ? "px-1" : "px-3"} ${
                                column.class
                              } list-unstyled`}
                            >
                              {column.type === "links" ? (
                                <>
                                  {column.url && column.title ? (
                                    <li>
                                      <NavDropdown.Item
                                        id={column.id}
                                        to={column.url}
                                        className={`font-weight-bold nav-link ${megaMenusClass} ${
                                          column.isActive ? "active-menu-item" : ""
                                        }`}
                                        as={Link}
                                      >
                                        {column.title} <Icon name="arrow-right" lib="far" class="ml-1" />
                                      </NavDropdown.Item>
                                    </li>
                                  ) : (
                                    column.title && (
                                      <li>
                                        <span
                                          className="h4-font-size px-3 d-flex text-nowrap"
                                          style={{ lineHeight: "1.75em" }}
                                        >
                                          {column.title}
                                        </span>
                                      </li>
                                    )
                                  )}
                                  {/* COLUMN MENU LINKS */}
                                  {column.menus.map((menu, index) => {
                                    const includesTrademark = menu.text.includes("&trade;");
                                    const cleanedTitle = menu.text.replaceAll("&trade;", "");
                                    const menuItemContent = (
                                      <>
                                        {/* menu.icon && menu.icon.position === "left" && <Icon {...menu.icon} /> */}
                                        {cleanedTitle}
                                        {includesTrademark && <>&trade;</>}
                                        {/* menu.icon && menu.icon.position === "right" && <Icon {...menu.icon} /> */}
                                      </>
                                    );
                                    return (
                                      menu.show && (
                                        <li key={index}>
                                          {menu.externalLink && (
                                            <NavDropdown.Item
                                              key={menu.id}
                                              id={menu.id}
                                              href={menu.url}
                                              target="_blank"
                                              className={`${menu.isActive ? "active-menu-item" : ""}`}
                                              rel="noopener noreferrer"
                                            >
                                              {menuItemContent}
                                            </NavDropdown.Item>
                                          )}
                                          {!menu.externalLink && (
                                            <NavDropdown.Item
                                              key={menu.id}
                                              id={menu.id}
                                              to={menu.url}
                                              as={Link}
                                              className={`${menu.isActive ? "active-menu-item" : ""}`}
                                            >
                                              {menuItemContent}
                                            </NavDropdown.Item>
                                          )}
                                        </li>
                                      )
                                    );
                                  })}
                                </>
                              ) : (
                                // MONEY TIPS COLUMNS
                                <div className="d-flex card border-0 flex-column align-items-between h-100">
                                  {column.popularArticleUrl && (
                                    <div className="blog-dropdown-item">
                                      <Link to={column.popularArticleUrl} id={column.id} className={`stretched-link`}>
                                        <span className="sr-only">{column.text}</span>
                                      </Link>
                                      {column.image.show && (
                                        <GatsbyImage
                                          image={popularArticleImgVar}
                                          id={column.image.id}
                                          className={column.image.class}
                                          style={{ maxHeight: "100%", pointerEvents: "none", borderRadius: "12px" }}
                                          imgStyle={{ objectFit: "contain" }}
                                          alt={column.image.altText}
                                        />
                                      )}
                                      {column.text && (
                                        <div className="py-2 font-weight-bold blog-dropdown-item">
                                          {column.text} <Icon name="arrow-right" lib="far" class="ml-1" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </ul>
                          );
                        })}
                      </div>

                      {/* ABOUT US LINKS (BOTTOM ROW) */}
                      {aboutUsLinks && aboutUsLinks.length > 0 && (
                        <div className="row border-top py-2 align-items-center w-100">
                          <ul className="col-md d-flex flex-row justify-content-between list-unstyled mb-0">
                            {aboutUsLinks.map((aboutUsLink) => {
                              return (
                                aboutUsLink.show && (
                                  <li key={aboutUsLink.id} className={`${aboutUsLink.class}`}>
                                    {aboutUsLink.externalLink ? (
                                      <a
                                        id={`${aboutUsLink.id}-${dropdown.title.replace(/ +/g, "-").toLowerCase()}`}
                                        className={`dropdown-item  ${aboutUsLink.isActive ? "active-menu-item" : ""}`}
                                        href={aboutUsLink.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {aboutUsLink.icon && aboutUsLink.icon.position === "left" && (
                                          <Icon {...aboutUsLink.icon} />
                                        )}
                                        {aboutUsLink.text}
                                        {aboutUsLink.icon && aboutUsLink.icon.position === "right" && (
                                          <Icon {...aboutUsLink.icon} />
                                        )}
                                      </a>
                                    ) : (
                                      <Link
                                        id={`${aboutUsLink.id}-${dropdown.title.replace(/ +/g, "-").toLowerCase()}`}
                                        className={`dropdown-item  ${aboutUsLink.isActive ? "active-menu-item" : ""}`}
                                        to={aboutUsLink.url}
                                      >
                                        {aboutUsLink.icon && aboutUsLink.icon.position === "left" && (
                                          <Icon {...aboutUsLink.icon} />
                                        )}
                                        {aboutUsLink.text}
                                        {aboutUsLink.icon && aboutUsLink.icon.position === "right" && (
                                          <Icon {...aboutUsLink.icon} />
                                        )}
                                      </Link>
                                    )}
                                  </li>
                                )
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </NavDropdown>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {isMenuOpen && (
        <div
          id="background"
          className="sticky-top position-absolute w-100 vw-100"
          style={{
            left: "0",
            height: "100vh",
            top: height,
            zIndex: "-1",
            opacity: "100",
            transition: "opacity .5s",
            background: "rgba(0,0,0,.5)"
          }}
        />
      )}
    </>
  );
};

export default MegaMenus;
