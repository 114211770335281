const englishMobileMenusData = [
  // PERSONAL BANKING
  {
    id: "mm-pb-landing",
    title: "Personal",
    landingTitle: "WaFd Bank Personal Banking",
    url: "/personal-banking",
    menus: [
      {
        id: "mmc-pb-checking",
        class: "",
        title: "Checking",
        url: "/personal-banking/checking-account",
        menus: [
          {
            id: "mml-pb-free-checking",
            title: "Free Checking",
            url: "/personal-banking/free-checking-account"
          },
          {
            id: "mml-pb-free-spending",
            title: "Spending Account",
            url: "/personal-banking/spending-account"
          },
          {
            id: "mml-pb-green-checking",
            title: "Rewards and Premium Rewards Checking",
            url: "/personal-banking/checking-account/rewards-checking"
          },
          {
            id: "mml-pb-stellar-checking",
            title: "Interest Checking",
            url: "/personal-banking/checking-account/interest-rewards-checking"
          },
          {
            id: "mml-pb-debit-cards",
            title: "Debit Cards",
            url: "/personal-banking/debit-cards"
          }
        ]
      },
      {
        id: "mmc-pb-savings",
        class: "",
        title: "Savings",
        url: "/personal-banking/savings-account",
        menus: [
          {
            id: "mml-pb-savings-money-market",
            title: "Savings and Money Markets",
            url: "/personal-banking/savings-account"
          },
          {
            id: "mml-pb-youth-savings",
            title: "Kid and Teen Savings",
            url: "/personal-banking/savings-account/youth-savings-account"
          },
          {
            id: "mml-pb-cds",
            title: "Certificates of Deposit (CDs)",
            url: "/personal-banking/savings-account/certificate-of-deposit"
          },
          {
            id: "mml-pb-retirement-planning",
            title: "Retirement Savings",
            url: "/personal-banking/retirement-ira-accounts"
          },
          {
            id: "mml-pb-education-savings",
            title: "Education Savings",
            url: "/personal-banking/savings-account/youth-savings-account#coverdell-section"
          }
        ]
      },
      {
        id: "mmc-pb-mortgages",
        class: "",
        title: "Mortgage",
        url: "/personal-banking/home-loans",
        menus: [
          {
            id: "mml-pb-mortgage-rates",
            title: "Rates",
            url: "/personal-banking/home-loans/mortgage-rates"
          },
          {
            id: "mml-pb-contact-loan-officer",
            title: "Contact a Loan Officer",
            url: "/personal-banking/contact-loan-officer"
          },
          {
            id: "mml-pb-home-equity-loans",
            title: "Home Equity",
            url: "/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan"
          },
          {
            id: "mml-pb-home-buyer",
            title: "First Time Buyer",
            url: "/personal-banking/home-loans/first-time-home-buyer-loans"
          },
          {
            id: "mml-pb-smart-start",
            title: "Smart Start",
            url: "/personal-banking/home-loans/smart-start-down-payment-assistance"
          },
          {
            id: "mml-pb-home-loans",
            title: "Home Loans",
            url: "/personal-banking/home-loans/home-mortgage"
          },
          {
            id: "mml-pb-refinance",
            title: "Refinancing",
            url: "/personal-banking/home-loans/mortgage-refinance"
          },
          {
            id: "mml-pb-home-improvement-loans",
            title: "Remodeling",
            url: "/personal-banking/home-loans/home-improvement-loans"
          },
          {
            id: "mml-pb-construction-loans",
            title: "Construction",
            url: "/personal-banking/home-loans/construction-loans"
          },
          {
            id: "mml-pb-lot-loans",
            title: "Lot Loans",
            url: "/personal-banking/home-loans/land-loans"
          },
          {
            id: "mml-cross-collateral",
            title: "Cross Collateral",
            url: "/personal-banking/home-loans/easy-move-cross-collateral"
          }
        ]
      },
      {
        id: "mmc-pb-credit-cards",
        class: "",
        title: "Credit Cards",
        url: "/personal-banking/credit-cards"
      },
      {
        id: "mmc-pb-personal-services",
        class: "border-left",
        title: "Banking with Us",
        menus: [
          {
            id: "mml-pb-online-banking",
            title: "Online Banking",
            url: "/personal-banking/online-banking",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-pb-mobile-app",
            title: "Mobile App",
            url: "/personal-banking/online-banking#mobile-app-section",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-pb-zelle",
            title: "Zelle",
            url: "/personal-banking/mobile-banking-app/zelle",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-pb-voice-banking",
            title: "Voice-Activated Banking",
            url: "/personal-banking/voice-recognition-technology",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-pb-select-banking",
            title: "Select Banking",
            url: "/personal-banking/private-banking-services",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-pb-account-rates",
            title: "Account Rates",
            url: "/personal-banking/interest-rates",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-pb-account-fees",
            title: "Account Fees",
            url: "/personal-banking/account-fees",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-pb-mortgage-rates",
            title: "Mortgage Rates",
            url: "/personal-banking/home-loans/mortgage-rates",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-pb-calculators",
            title: "WaFd Bank Calculators",
            url: "/personal-banking/online-loan-banking-calculators"
          }
        ]
      }
    ]
  },
  // BUSINESS BANKING
  {
    id: "mm-bb-landing",
    title: "Business",
    landingTitle: "WaFd Bank Small Business Banking",
    url: "/business-banking/small-business",
    menus: [
      {
        id: "mmc-bb-checking",
        class: "",
        title: "Business Checking",
        url: "/business-banking/business-checking-account",
        menus: [
          {
            id: "mml-bb-simple-checking",
            title: "Free Business Checking",
            url: "/business-banking/business-checking-account/free-business-checking"
          },
          {
            id: "mml-bb-interest-checking",
            title: "Business Interest Checking",
            url: "/business-banking/business-checking-account/interest-checking"
          },
          {
            id: "mml-bb-analyzed-checking",
            title: "Business Analyzed Checking",
            url: "/business-banking/business-checking-account#business-checking-overview-section"
          }
        ]
      },
      {
        id: "mmc-bb-savings",
        class: "",
        title: "Business Savings",
        url: "/business-banking/business-savings-account",
        menus: [
          {
            id: "mml-bb-savings-account",
            title: "Business Savings Account",
            url: "/business-banking/business-savings-account/small-business"
          },
          {
            id: "mml-bb-money-market",
            title: "Business Money Market",
            url: "/business-banking/business-savings-account/money-market-account"
          },
          {
            id: "mml-bb-premium-money-market",
            title: "Business Premium Money Market",
            url: "/business-banking/business-savings-account/high-yield-money-market-account"
          },
          {
            id: "mml-bb-cds-special",
            title: "Business CD Special",
            url: "/business-banking/business-savings-account#business-savings-overview-section-cds"
          },
          {
            id: "mml-bb-cds",
            title: "Business CDs",
            url: "/business-banking/business-savings-account#business-savings-overview-section-cds"
          }
        ]
      },
      {
        id: "mmc-bb-credit",
        class: "",
        title: "Credit",
        url: "/business-banking/business-line-of-credit",
        menus: [
          {
            id: "mml-bb-small-business-credit",
            title: "Small Business Credit Cards",
            url: "/business-banking/small-business-credit-card"
          },
          {
            id: "mml-bb-credit-solutions",
            title: "Credit Solutions",
            url: "/business-banking/business-line-of-credit"
          },
          {
            id: "mml-bb-line-of-credit",
            title: "Business Lines of Credit",
            url: "/business-banking/business-line-of-credit#business-lines-of-credit-section"
          },
          {
            id: "mml-bb-commercial-real-estate",
            title: "Commercial Real Estate Financing",
            url: "/business-banking/business-line-of-credit#commercial-real-estate-section"
          },
          {
            id: "mml-bb-business-term-loans",
            title: "Business Term Loans",
            url: "/business-banking/business-line-of-credit#business-term-loans-section"
          },
          {
            id: "mml-bb-ppp-forgiveness",
            title: "PPP Forgiveness",
            url: "/business-banking/small-business/ppp-forgiveness"
          }
        ]
      },
      {
        id: "mmc-bb-business-services",
        class: "",
        title: "Business Services",
        url: "/business-banking/business-services",
        menus: [
          {
            id: "mml-bb-payment-processing-services",
            title: "Payment Processing Services",
            url: "/business-banking/business-services/payment-processing-services"
          },
          {
            id: "mml-bb-hr-and-payroll-services",
            title: "HR and Payroll",
            url: "/business-banking/business-services/hr-and-payroll-services"
          },
          {
            id: "mml-bb-point-of-sale-inventory",
            title: "POS Inventory",
            url: "/business-banking/business-services/pos-and-inventory-systems"
          },
          {
            id: "mml-bb-customer-engagement",
            title: "Customer Engagement",
            url: "/business-banking/business-services/customer-engagement"
          },
          {
            id: "mml-bb-collect-early",
            title: "CollectEarly&trade;",
            url: "/business-banking/business-services/collect-early"
          },
          {
            id: "mml-bb-accounting-automation",
            title: "Accounting Automation",
            url: "/business-banking/business-services/accounting-automation"
          },
          {
            id: "mml-bb-cash-flow-management",
            title: "Cash Flow Management",
            url: "/business-banking/business-services/cash-flow-management"
          }
        ]
      },
      {
        id: "mmc-bb-personal-services",
        class: "border-left",
        title: "Banking With Us",
        //url: "/business-banking/online-banking",
        menus: [
          {
            id: "mml-bb-online-banking",
            title: "Online Banking",
            url: "/business-banking/online-banking",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-bb-treasury-express",
            externalLink: true,
            title: "Treasury Express Login",
            url: "https://commercial.washingtonfederal.com/smallbusiness/auth",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-bb-treasury-prime",
            externalLink: true,
            title: "Treasury Prime Login",
            url: "https://commercial.washingtonfederal.com",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-bb-banking-officer",
            title: "Find a Banking Officer",
            url: "/business-banking/near-me",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          },
          {
            id: "mml-bb-business-rates",
            title: "Business Rates",
            url: "/business-banking/business-cd-money-market-rates",
            icon: {
              position: "left",
              lib: "fas",
              name: "chevron-right",
              class: "mt-1 mr-2"
            }
          }
        ]
      }
    ]
  },

  // COMMERCIAL BANKING
  {
    id: "mm-cb-landing",
    title: "Commercial",
    landingTitle: "WaFd Bank Commercial Banking",
    url: "/commercial-banking",
    menus: [
      {
        id: "mmc-cb-commercial-lending",
        class: "",
        title: "Commercial Lending",
        url: "/commercial-banking/commercial-lending",
        menus: [
          {
            id: "mml-cb-line-of-credit",
            title: "Commercial Line of Credit",
            url: "/commercial-banking/commercial-line-of-credit"
          },
          {
            id: "mml-cb-equipment-financing",
            title: "Equipment Finance",
            url: "/commercial-banking/business-equipment-financing"
          },
          {
            id: "mml-cb-acquisition-financing",
            title: "Acquisition Financing",
            url: "/commercial-banking/acquisition-financing"
          },
          {
            id: "mml-cb-real-estate-financing",
            title: "Owner Occupied Real Estate",
            url: "/commercial-banking/owner-occupied-real-estate-financing"
          },
          {
            id: "mml-cb-term-loans",
            title: "Commercial Term Loans",
            url: "/commercial-banking/commercial-term-loans"
          },
          {
            id: "mml-cb-local-commercial-team",
            title: "Local Commercial Team",
            url: "/commercial-banking#local-commercial-team"
          }
        ]
      },
      {
        id: "mmc-cb-real-estate",
        class: "",
        title: "Commercial Real Estate",
        url: "/commercial-banking/commercial-real-estate-loans",
        menus: [
          {
            id: "mml-cb-real-estate-loans",
            title: "Commercial Real Estate",
            url: "/commercial-banking/commercial-real-estate-loans"
          },
          {
            id: "mml-cb-multifamily-and-self-storage-loans",
            title: "Community Real Estate Lending",
            url: "/commercial-banking/multifamily-self-storage-loans"
          },
          {
            id: "mml-cb-real-estate-products-services",
            title: "Products and Services",
            url: "/commercial-banking/commercial-real-estate-products-and-services"
          },
          {
            id: "mml-cb-wafd-client-connect",
            title: "WAFD Client Connect",
            url: "/commercial-banking/commercial-client-connect"
          }
        ]
      },
      {
        id: "mmc-cb-treasury-management",
        class: "",
        title: "Treasury Management",
        url: "/commercial-banking/treasury-management-services",
        menus: [
          {
            id: "mml-cb-business-zelle",
            title: "Zelle",
            url: "/business-banking/online-banking/zelle"
          },
          {
            id: "mml-cb-deposit-accounts",
            title: "Deposit Accounts",
            url: "/commercial-banking/treasury-management-services/deposit-accounts"
          },
          {
            id: "mml-cb-security-fraud",
            title: "Security & Fraud",
            url: "/commercial-banking/treasury-management-services/security-fraud"
          },
          {
            id: "mml-cb-treasury-express",
            title: "WAFD Treasury Express",
            url: "/commercial-banking/treasury-management-services/express"
          },
          {
            id: "mml-cb-treasury-prime",
            title: "WAFD Treasury Prime",
            url: "/commercial-banking/treasury-management-services/prime"
          },
          {
            id: "mml-cb-receivables",
            title: "Receivables",
            url: "/commercial-banking/treasury-management-services/receivables"
          },
          {
            id: "mml-cb-payables",
            title: "Payables",
            url: "/commercial-banking/treasury-management-services/payables"
          }
        ]
      },
      {
        id: "mmc-cb-specialized-industries",
        class: "",
        title: "Specialized Industries",
        url: "/commercial-banking/specialized-industries",
        menus: [
          {
            id: "mml-cb-government-banking",
            title: "Government & Institutional Banking",
            url: "/commercial-banking/specialized-industries/institutional-government-banking"
          },
          {
            id: "mml-cb-agribusiness-banking",
            title: "Agribusiness",
            url: "/commercial-banking/specialized-industries/agribusiness-banking"
          },
          {
            id: "mml-cb-senior-housing",
            title: "Senior Housing",
            url: "/commercial-banking/specialized-industries/senior-housing"
          },
          {
            id: "mml-cb-professional-service-firms",
            title: "Professional Service Firms",
            url: "/commercial-banking/specialized-industries/professional-service-firms"
          }
        ]
      }
    ]
  },
  // WAFD MONEY TIPS (BLOG ARTICLES)
  {
    id: "mm-money-tips",
    title: "Money Tips",
    landingTitle: "WaFd Money Tips",
    url: "/blog",
    menus: [
      {
        id: "mml-blog-banking-101",
        title: "Banking 101",
        url: "/blog/banking-101"
      },
      {
        id: "mml-blog-building-remodeling",
        title: "Building & Remodeling",
        url: "/blog/building-remodeling"
      },
      {
        id: "mml-blog-building-credit",
        title: "Building Credit",
        url: "/blog/building-credit"
      },
      {
        id: "mml-blog-community-support",
        title: "Community Support",
        url: "/blog/community-support"
      },
      {
        id: "mml-blog-consumer-tips",
        title: "Consumer Tips",
        url: "/blog/consumer-tips"
      },
      {
        id: "mml-blog-family-finance",
        title: "Family Finance",
        url: "/blog/family-finance"
      },
      {
        id: "mml-blog-home-ownership",
        title: "Home Ownership",
        url: "/blog/home-ownership"
      },
      {
        id: "mml-blog-security-privacy",
        title: "Security & Privacy",
        url: "/blog/security-privacy"
      },
      {
        id: "mml-blog-small-business",
        title: "Small Business",
        url: "/blog/small-business"
      }
    ]
  },
  // ABOUT US
  {
    id: "mm-about-us",
    title: "About WaFd Bank",
    landingTitle: "About Us",
    url: "/about-us",
    menus: [
      {
        id: "mm-ab-wafd-sports",
        class: "ml-3",
        title: "WaFd Sports",
        url: "/about-us/wafd-sports"
      },
      {
        id: "mm-ab-careers",
        class: "ml-3",
        title: "Careers",
        url: "/about-us/banking-careers"
      },
      {
        id: "mm-ab-community-relations",
        class: "ml-3",
        title: "Community Relations",
        url: "/about-us/community-giving"
      },
      {
        id: "mm-ab-investor-relations",
        class: "ml-3",
        title: "Investor Relations",
        url: "/about-us/investor-relations"
      },
      {
        id: "mm-ab-faqs",
        class: "ml-3",
        title: "FAQs",
        url: "/customer-service/faq"
      },
      {
        id: "mm-ab-contact-us",
        class: "ml-3",
        title: "Contact Us",
        url: "/about-us/contact-us"
      }
    ]
  }
];

const spanishMobileMenusData = [
  // PERSONAL BANKING
  {
    id: "mm-es-pb-landing",
    englishTitle: "Personal Banking",
    landingTitle: "Banca personal",
    title: "Banca personal",
    url: "/es", // There is no /es/banca-personal page. There is a redirect, but we're skipping it here.
    menus: [
      {
        id: "mmc-es-pb-checking",
        class: "",
        englishTitle: "Checking",
        title: "Cuenta de cheques",
        url: "/es/banca-personal/cuenta-de-cheques",
        menus: [
          {
            id: "mml-es-pb-free-checking",
            englishText: "Free Checking",
            title: "Cuenta de cheques gratuitos",
            url: "/es/banca-personal/gratis-cuenta-de-cheques"
          },
          {
            id: "mml-es-pb-rewards-and-premium-rewards-checking",
            englishText: "Rewards and Premium Rewards Checking",
            title: "Rewards y Premium Rewards Checking",
            url: "/es/banca-personal/rewards-checking"
          },
          {
            id: "mml-es-pb-interest-checking",
            englishText: "Interest Checking",
            title: "Interest Checking",
            url: "/es/banca-personal/interest-rewards-checking"
          },
          {
            id: "mml-es-pb-fresh-start",
            englishText: "Fresh Start Account",
            title: "Cuenta Nuevo Comienzo (Fresh Start)",
            url: "/es/banca-personal/cuenta-nuevo-comienzo"
          }
        ]
      },
      {
        id: "mmc-es-pb-savings",
        class: "",
        englishTitle: "Savings",
        title: "Cuentas de ahorro",
        url: "/es/banca-personal/cuentas-de-ahorro",
        menus: [
          {
            id: "mml-es-pb-savings-cds-money-market",
            englishTitle: "Savings, CD and Money Market",
            title: "Cuentas de ahorro,  cuentas a plazo fijo y cuentas Money Market",
            url: "/es/banca-personal/cuentas-de-ahorro"
          },
          {
            id: "mml-es-pb-retirement-planning",
            englishTitle: "Retirement Savings",
            title: "Cuentas individuales de retiro o jubilación (IRA)",
            url: "/es/banca-personal/jubilacion-ira"
          }
        ]
      },
      {
        id: "mmc-es-pb-mortgages",
        class: "",
        englishTitle: "Mortgage",
        title: "Préstamos hipotecarios",
        url: "/es/banca-personal/prestamos-hipotecarios",
        menus: [
          {
            id: "mml-es-pb-home-loans-apply",
            englishTitle: "Apply for a Loan",
            title: "Contacte a un asesor hipotecario",
            url: "/es/banca-personal/contacte-a-un-asesor-hipotecario"
          },
          {
            id: "mml-pb-land-loans",
            englishTitle: "Lot Loans",
            title: "Préstamos para terrenos",
            url: "/es/banca-personal/prestamos-hipotecarios/prestamos-para-lotes-y-terrenos"
          },
          {
            id: "mml-es-pb-smart-start",
            englishTitle: "Smart Start",
            title: "Hipoteca Smart Start",
            url: "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start"
          },
          {
            id: "mml-es-pb-homeowner-assistance",
            englishTitle: "Assistance",
            title: "Asistencia al propietario",
            url: "/es/banca-personal/prestamos-hipotecarios/asistencia-a-propietarios"
          }
        ]
      },
      {
        id: "mmc-es-pb-credit-cards",
        class: "",
        englishTitle: "Credit Cards",
        title: "Tarjetas de crédito",
        url: "/es/banca-personal/tarjetas-de-credito"
      },
      {
        id: "mmc-es-pb-personal-services",
        class: "border-left",
        englishTitle: "Additional Services",
        title: "Servicios adicionales",
        menus: [
          {
            id: "mml-es-pb-account-rates",
            englishTitle: "Account Rates",
            title: "Tasas de interés",
            url: "/es/banca-personal/tasas-de-interes"
          },
          {
            id: "mml-es-pb-account-fees",
            englishTitle: "Account Fees",
            title: "Tarifas del consumidor",
            url: "/es/banca-personal/cargos"
          },
          {
            id: "mml-es-pb-mortgage-rates",
            englishTitle: "Mortgage Rates",
            title: "Tasas de interés para cuentas hipotecarias",
            url: "/es/banca-personal/tasas-de-interes-hipotecarias-actuales"
          },
          {
            id: "mml-es-pb-faqs",
            englishTitle: "FAQs",
            title: "Preguntas frecuentes",
            url: "/es/preguntas-frecuentes"
          },
          {
            id: "mml-es-pb-contact-us",
            englishTitle: "Contact Us",
            title: "Contáctanos",
            url: "/es/contactanos"
          }
        ]
      }
    ]
  },
  {
    id: "mm-es-sb-landing",
    englishTitle: "Business Banking",
    landingTitle: "Negocio",
    title: "Negocio",
    url: "/es/banca-de-negocios",
    menus: []
  },
  {
    id: "mm-es-money-tips",
    englishTitle: "Money Tips",
    landingTitle: "WaFd Bank consejos de dinero",
    title: "WaFd Bank consejos de dinero",
    url: "/es/tu-decides",
    menus: []
  }
];

module.exports = {
  englishMobileMenusData,
  spanishMobileMenusData
};
